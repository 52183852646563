import { OrgTeam, RoleType, getEmailDomainFromUsername } from '@spectral/types'
import omit from 'lodash/omit'
import { authPersistence, teamPersistence } from '../persistence'
import track from '../../common/track'
import { sdkClient, RootState } from '../store'

export type User = {
  token: string
  firstName: string
  avatarUrl: string
  lastName: string
  username: string
  isEmailVerified: boolean
  isNeedUpdateOrgName: boolean
  hasScanForTeam: boolean
  isFloatingUser: boolean
  agentDetails: any
  downloaderBaseUrl: any
  role: RoleType
  orgTeams: Array<OrgTeam>
}

// todo - understand the effects of this type
type UserSettings = {
  notification: {
    email: {
      enabled: boolean
    }
  }
  orgTeams: Array<string>
}

export type AuthState = {
  user: User
  detectors: any
  userSettings: UserSettings
  error: string | null
  isGenericError: boolean
  invitationInfo: {
    email: string
    company: string
  }
  teamPid: string
}

type LoginPayload = {
  username: string
  password: string
  recaptcha: string
}

const initialState: AuthState = {
  user: null,
  error: null,
  isGenericError: false,
  userSettings: null,
  detectors: null,
  invitationInfo: {
    email: '',
    company: '',
  },
  teamPid: null,
}
export const auth = {
  state: initialState,
  reducers: {
    setUser(state: AuthState, user): AuthState {
      track.user(user.username)
      track.addOrUpdateUser(user)
      return { ...state, user, error: null }
    },
    setTeamPid(state: AuthState, teamPid): AuthState {
      return { ...state, teamPid }
    },
    setError(state: AuthState, error: string): AuthState {
      return { ...state, error }
    },
    setIsGenericError(state: AuthState, isGenericError: boolean): AuthState {
      return { ...state, isGenericError }
    },
    userLoggedOut(state: AuthState) {
      return { ...state, user: null, userSettings: null }
    },
    reset(): AuthState {
      return initialState
    },
    setInvitationInfo(state: AuthState, invitationInfo): AuthState {
      return { ...state, invitationInfo }
    },
    setUserSettings(state: AuthState, userSettings): AuthState {
      return { ...state, userSettings }
    },
  },
  effects: (dispatch: any) => ({
    async login({ username, password, recaptcha }: LoginPayload) {
      try {
        dispatch.RecoverFinish.setMessage(null)
        const user = await sdkClient
          .auth()
          .login({ data: { email: username, password, recaptcha } })
        if (!user) {
          throw new Error()
        }
        dispatch.Auth.setUser(user)
        track.event('LOGIN', { username })
        authPersistence.persist(omit(user, ['username', 'token']))
        dispatch.Auth.fetchSettings()
        dispatch.EmailVerification.setIsEmailAlertVisible(
          user.isEmailVerificationShown
        )
        dispatch.Teams.fetchCurrentTeam()
      } catch (error) {
        dispatch.Auth.setIsGenericError(true)
        throw new Error(error.message)
      }
    },
    checkAuthorization() {
      const user = authPersistence.get()
      if (user) {
        dispatch.Auth.setUser(user)
        dispatch.Auth.me()
      }
    },
    async logout() {
      await sdkClient.auth().logout()
      authPersistence.remove()
      teamPersistence.remove()
      dispatch.Auth.userLoggedOut()
      track.event('LOGOUT')
    },
    postUserFetch(user) {
      dispatch.Auth.setUser(user)
      authPersistence.persist(omit(user, ['username']))
      dispatch.Auth.fetchSettings()
      dispatch.EmailVerification.setIsEmailAlertVisible(
        user.isEmailVerificationShown
      )
      dispatch.Teams.fetchCurrentTeam()
    },
    async me() {
      try {
        const user: any = await sdkClient.users().me()

        if (!user) {
          throw Error('wrong username or password')
        }
        dispatch.Auth.postUserFetch(user)
      } catch (e) {
        if (JSON.parse(e.message).error === 'bad request')
          dispatch.Auth.logout()
        dispatch.Auth.setError(e.toString())
      }
    },
    async fetchInvitationInfo({ invite }) {
      const invitationInfo = await sdkClient
        .auth()
        .invitationInfo({ params: { invite } })
      dispatch.Auth.setInvitationInfo(invitationInfo)
    },
    async refetchUser() {
      const user: any = await sdkClient.users().me()
      dispatch.Auth.setUser(user)
      authPersistence.persist(omit(user, ['username']))
    },
    async updateSettings(settings) {
      const updatedSettings = await sdkClient
        .users()
        .updateSettings({ data: settings })
      dispatch.Auth.setUserSettings(updatedSettings)
    },
    async fetchSettings() {
      const userSettings = await sdkClient.users().getSettings()
      dispatch.Auth.setUserSettings(userSettings)
    },
    async dismissAlert(alertType) {
      const updatedSettings = await sdkClient
        .users()
        .dismissAlert({ data: { alertType } })
      dispatch.Auth.setUserSettings(updatedSettings)
    },
    async fetchTeamPidForSsoLogin({ email, recaptcha }) {
      try {
        const res: any = await sdkClient
          .auth()
          .ssoLoginTeamPid({ data: { email, recaptcha } })
        if (!res?.data?.teamPid) {
          throw new Error('We could not locate an account with this domain')
        }
        track.event('SSO Signin', { teamPid: res.data.teamPid })
        dispatch.Auth.setTeamPid(res.data.teamPid)
      } catch (error) {
        dispatch.Auth.setIsGenericError(true)
        throw new Error(error.message)
      }
    },
    async generateApiKey() {
      try {
        await sdkClient.users().generateApiKey()
        dispatch.Auth.me()
      } catch (error) {
        // TODO: Handle error
        console.log(error)
      }
    },
  }),
  selectors: (_slice, createSelector, _hasProps) => ({
    currentUserName() {
      return createSelector(
        // @ts-ignore
        (rootState: RootState) => rootState.Auth,
        (authState, _rootState: RootState) => authState.user.username
      )
    },
    criticalIssuesBanner() {
      return createSelector(
        // @ts-ignore
        (rootState: RootState) => rootState.Auth,
        (authState, _rootState: RootState) =>
          authState.user.shouldDisplayCriticalIssuesBanner
      )
    },
    settingsPage() {
      return (rootState) => {
        return {
          userEmailDomain: getEmailDomainFromUsername(
            rootState.Auth.user?.username || ''
          ),
          isEmailVerified: rootState.Auth.user?.isEmailVerified,
          isEmailNotificationsEnabled:
            rootState.Auth.userSettings?.notification?.email?.enabled,
          isDailyEmailReportEnabled:
            rootState.Auth.userSettings?.notification?.email
              ?.subscribeToDailyDigestReport,
          isWeeklyEmailReportEnabled:
            rootState.Auth.userSettings?.notification?.email
              ?.subscribeToWeeklyDigestReport,
          isUpdatingSettings: rootState.loading.effects.Auth.updateSettings > 0,
          isLoadingSettings:
            rootState.loading.effects.Auth.fetchSettings > 0 &&
            rootState.Auth.userSettings === null,
          activeTeams: rootState.Auth.userSettings?.orgTeams,
        }
      }
    },
  }),
}
