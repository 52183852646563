import { removeFilePathSlash } from './formatters'

export enum IssueStatus {
  Active = 'Active',
  Ignored = 'Ignored',
  Resolved = 'Resolved',
}

export enum ValidationResult {
  Valid = 'valid',
  Invalid = 'invalid',
  Error = 'error',
}

export interface IssueIntegrations {
  jira?: {
    jiraIssueKey: string
  }
  monday?: {
    mondayItemId: string
    mondayBoardId: string
  }
}
export interface IssueAssignData {
  assigneeEmail: string
  assignedByEmail: string
  assignDate: Date
}

export interface IssueIgnoreData {
  ignoreDate?: Date
  ignoreType: IssueIgnoreTypes
  ignoredByEmail?: string
  comment: string
  timeSnoozeOver?: Date
  source?: IgnoreCategorizationConfig | IgnoreCategorizationInline
}

export interface IssueValidationData {
  result: ValidationResult
  lastValidation: Date
}

export interface IgnoreCategorizationConfig {
  config: {
    path: string
  }
}

export interface IgnoreCategorizationInline {
  inline: {
    path: string
  }
}

export enum IgnoreSourceName {
  Config = 'config',
  Inline = 'inline',
  Remote = 'remote',
}

export enum IssueIgnoreTypes {
  wontFix = 'wont-fix',
  falsePositive = 'false-positive',
  snooze = 'snooze',
}

export const IssueIgnoreTypeView = {
  [IssueIgnoreTypes.wontFix]: "Won't fix",
  [IssueIgnoreTypes.falsePositive]: 'False Positive',
  [IssueIgnoreTypes.snooze]: 'Snooze',
}

export enum IssueIgnoreAction {
  remove = 'remove',
  add = 'add',
}

export const ISSUE_UNASSIGNED = 'Unassigned'

export const IAC_TAG = 'iac'
export const TFPLAN_TAG = 'tfplan'
export const IAC_TAGS = [IAC_TAG, TFPLAN_TAG]
export const OSS_TAG = 'oss'
export const DISCOVER_TAG = 'discover'
export const CUSTOM_RULES = 'custom-rules'

export enum OpenSourceIssueType {
  VULNERABLE = 'VULNERABLE',
  MALICIOUS = 'MALICIOUS',
}

export enum IssueContent {
  Vendor = 'vendor',
  Binary = 'binary',
  Docs = 'docs',
  Tests = 'tests',
  Examples = 'examples',
  CodeInfra = 'code/infra',
  DataInfra = 'data/infra',
  Files = 'files',
}

export enum IssueKind {
  Secrets = 'secrets',
  Iac = 'iac',
  Cicdhardening = 'ci-cd-hardening',
  Oss = 'oss',
}

export const issueTagsToKind = (tags: Array<string>): IssueKind => {
  if (tags?.includes(IAC_TAG) || tags?.includes(TFPLAN_TAG)) {
    return IssueKind.Iac
  }
  if (tags?.includes(OSS_TAG)) {
    return IssueKind.Oss
  }
  if (tags?.includes(DISCOVER_TAG)) {
    return IssueKind.Cicdhardening
  }

  return IssueKind.Secrets
}

export const buildHostIssuePath = ({ issue }) => {
  const { filePath, metadata } = issue
  const filePathPreset = removeFilePathSlash(filePath)

  if (
    !filePathPreset.includes('#L') &&
    metadata?.position?.lineStart &&
    metadata?.position?.lineEnd
  ) {
    const lines =
      metadata.position.lineStart === metadata.position.lineEnd
        ? `#L${issue.metadata.position.lineStart}`
        : `#L${issue.metadata.position.lineStart}-L${issue.metadata.position.lineEnd}`
    return filePathPreset.concat(lines)
  }

  return filePathPreset
}
