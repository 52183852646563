import React, { useEffect } from 'react'
import { Box } from 'theme-ui'
import { useSelector, useDispatch } from 'react-redux'
import { Page } from '../../components/page'
import { select } from '../../redux/store'
import { ProfileSettings } from '../../blocks/settings/profile'
import ApiKeyConnected from './team/api-key/api-key-connected'
import {
  ContentPanel,
  ContentPanelHeader,
} from '../../components/panels/content'
import DSNConnected from './team/dsn/dsn-connected'

export default () => {
  const {
    isEmailNotificationsEnabled,
    isDailyEmailReportEnabled,
    isWeeklyEmailReportEnabled,
    isEmailVerified,
    isLoadingSettings,
  } = useSelector(select.Auth.settingsPage)

  const { emailSettings } = useSelector(select.Teams.teamSettings)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch.Auth.fetchSettings()
    dispatch.Teams.fetchSettings()
  }, [dispatch.Auth, dispatch.Teams])

  return (
    <Page
      name="SETTINGS_PROFILE"
      title="Profile Settings"
      isLoading={isLoadingSettings}
    >
      <Box sx={{ padding: '40px' }}>
        <ProfileSettings
          isEmailVerified={isEmailVerified}
          isTeamEmailEnabled={emailSettings.enabled}
          isTeamDailyDigestReportEnabled={emailSettings.enableDailyDigestReport}
          isTeamWeeklyDigestReportEnabled={
            emailSettings.enableWeeklyDigestReport
          }
          settings={{
            email: {
              isEmailNotificationsEnabled,
              isDailyEmailReportEnabled,
              isWeeklyEmailReportEnabled,
            },
            onSettingsSave: dispatch.Auth.updateSettings,
          }}
        />

        <ContentPanel>
          <ContentPanelHeader>Auth Tokens</ContentPanelHeader>
          <ApiKeyConnected />
          <DSNConnected />
        </ContentPanel>
      </Box>
    </Page>
  )
}
