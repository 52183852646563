import React from 'react'
import pull from 'lodash/pull'
import { Select } from 'antd'
import { Box, Text } from 'theme-ui'
import { AssetCategory } from '@spectral/types'

export const AssetTypeFilter = ({ onChange, activeFilters }) => (
  <Box>
    <Select
      mode="multiple"
      placeholder="Filter by asset type"
      maxTagCount={3}
      value={activeFilters}
      onChange={onChange}
      style={{ width: 300, height: 47 }}
    >
      {Object.values(AssetCategory).map((value) => (
        <Select.Option value={value} key={value}>
          <Text sx={{ textTransform: 'capitalize' }}>{value}</Text>
        </Select.Option>
      ))}
    </Select>
  </Box>
)
