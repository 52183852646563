/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import uniqBy from 'lodash/uniqBy'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import pull from 'lodash/pull'
import { IssuesSortBy, AssetPageTab, SortDirection } from '@spectral/types'
import { IssueKind, IssueStatus } from '@spectral/types/issue'
import { castArray } from 'lodash'
import useListPageManagement from '../../../hooks/useListPageManagement'
import assetPageTracker from '../../../common/track/asset-page'
import { Dispatch, select } from '../../../redux/store'
import DiscoverIssuesView from '../../../blocks/asset-page/discover/discover-view/view'
import useIssuesActionIntegrations from '../../../hooks/useIssuesActionIntegrations'
import { useIssuesTableActions } from '../../../hooks/useIssuesTableActions'
import useExportToFile from '../../../hooks/useExportToFile'
import { SubPage } from '../../../components/tab/sub-page'

export const discoverIssuesListInitialParams = {
  page: 1,
  pageSize: 10,
  sortBy: IssuesSortBy.SEVERITY,
  sortDirection: SortDirection.ASC,
  tab: AssetPageTab.DiscoverIssues,
}

const filtersKeys = [
  'tab',
  'path',
  'severity',
  'status',
  'assignees',
  'detectorId',
  'issueId',
  'resources',
]

const discoverListInitialFiltersParams = pick(
  discoverIssuesListInitialParams,
  filtersKeys
)

export default ({ fetchAsset, asset }) => {
  const dispatch: Dispatch = useDispatch()
  const { assetId } = useParams()
  const isDownloadingExport = useSelector(select.AssetPage.isDownloadingExport)
  const { discoverIssues, pagination, status, filtersData } = useSelector(
    select.AssetPage.discoverList
  )
  const {
    status: { isLoadingAssignableMembers, isAssignableMembersLoaded },
    assetInfo: { category },
    assignableMembers,
  } = useSelector(select.AssetPage.assetPage)

  const { loaded, isRefreshing } = status

  const { integrations } = useIssuesActionIntegrations()
  const [selectedDiscoverIssues, setSelectedDiscoverIssues] = useState([])
  const onDiscoverIssuesSelectionChanged = (
    discoverIssuesToChange,
    isSelected
  ) => {
    const updatedSelectedDiscoverIssues = isSelected
      ? uniqBy([...selectedDiscoverIssues, ...discoverIssuesToChange], 'pid')
      : pull(selectedDiscoverIssues, ...discoverIssuesToChange)
    setSelectedDiscoverIssues([...updatedSelectedDiscoverIssues])
  }
  const resetTableState = () => {
    setSelectedDiscoverIssues([])
  }

  const [
    currentQueryStringParams,
    setQueryStringParam,
    clearQueryStringParam,
    onPaginationChange,
    trackFilter,
    trackSort,
    fetchData,
  ] = useListPageManagement(
    [
      'tab',
      'severity',
      'content',
      'status',
      'assignees',
      'path',
      'detectorId',
      'issueId',
    ],
    discoverIssuesListInitialParams,
    dispatch.AssetPage.fetchDiscoverIssues,
    dispatch.AssetPage.resetDiscoverIssues,
    assetPageTracker.filter.bind(assetPageTracker),
    assetPageTracker.sort.bind(assetPageTracker),
    assetPageTracker.pagination.bind(assetPageTracker),
    { assetId }
  )

  const [isDataFiltered, setIsDataFiltered] = useState(false)
  useEffect(() => {
    const currentParamsValuesPick = pick(currentQueryStringParams, filtersKeys)
    setIsDataFiltered(
      !isEqual(currentParamsValuesPick, discoverListInitialFiltersParams)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentQueryStringParams)])

  const [exportToCsv] = useExportToFile(
    () => assetPageTracker.export.bind(assetPageTracker)('csv', false),
    () =>
      dispatch.AssetPage.exportIssues({
        ...currentQueryStringParams,
        category,
        assetId: [assetId],
        issueKinds: castArray(IssueKind.Cicdhardening),
        output: 'csv',
      }),
    'csv',
    currentQueryStringParams
  )

  const [exportToPdf] = useExportToFile(
    () => assetPageTracker.export.bind(assetPageTracker)('pdf', false),
    () =>
      dispatch.AssetPage.exportIssues({
        ...currentQueryStringParams,
        category,
        assetId: [assetId],
        issueKinds: castArray(IssueKind.Cicdhardening),
        output: 'pdf',
      }),
    'pdf',
    currentQueryStringParams
  )

  useEffect(() => {
    setQueryStringParam({ status: IssueStatus.Active })
    return dispatch.AssetPage.resetFilters
  }, [])

  const exportActions = useMemo(() => {
    return {
      csv: { active: true, onExport: exportToCsv },
      pdf: { active: true, onExport: exportToPdf },
    }
  }, [exportToCsv, exportToPdf])

  const refetchAllData = useCallback(() => {
    fetchAsset()
    fetchData()
    resetTableState()
  }, [fetchAsset, fetchData])

  const handlers = useIssuesTableActions('asset_page', {
    ignore: refetchAllData,
    snooze: refetchAllData,
    resolve: refetchAllData,
    unResolve: refetchAllData,
    assigneeChange: refetchAllData,
    assigneeRemove: refetchAllData,
    severityChange: () => {
      fetchData({ updateOnlyFilters: true })
    },
  })

  return (
    <SubPage isLoading={!loaded} name="Discover" sx={{ height: '100%' }}>
      <DiscoverIssuesView
        asset={asset}
        isRefreshing={isRefreshing}
        loaded={loaded}
        onPaginationChange={onPaginationChange}
        trackFilter={trackFilter}
        trackSort={trackSort}
        clearQueryStringParam={clearQueryStringParam}
        setQueryStringParam={setQueryStringParam}
        currentQueryStringParams={currentQueryStringParams}
        filtersData={filtersData}
        discoverIssues={discoverIssues}
        pagination={pagination}
        assignableMembers={assignableMembers}
        actionHandlers={handlers}
        onDiscoverIssuesSelectionChanged={onDiscoverIssuesSelectionChanged}
        selectedDiscoverIssues={selectedDiscoverIssues}
        exportActions={exportActions}
        isDownloadingExport={isDownloadingExport}
        integrations={integrations}
        onActionIntegrationSubmit={refetchAllData}
        isDataFiltered={isDataFiltered}
        isLoadingAssignableMembers={isLoadingAssignableMembers}
        isAssignableMembersLoaded={isAssignableMembersLoaded}
      />
    </SubPage>
  )
}
